<template>
   <div class="errorPage">
        <div class="block">
            <img src="@/assets/img/pay/error.png" alt="">
            <div class="tip">
              The order does not exist or has expired,<br/> place a new order please
            </div>
        </div>
        <div class="bottom">
            <img src="@/assets/img/pay/bottom.jpg" alt="">
            <div class="tips">Dear Member , 100% Secure Payment Powered</div>
        </div>
   </div>
  </template>
  
  <script setup>
  import { ref, reactive, onMounted, toRefs,onUnmounted } from "vue";
  import { useRoute, useRouter } from "vue-router";

    const route = useRoute();
    const router = useRouter()
    let state = reactive({
      
    });
    
    onMounted(async () =>{
      document.title = 'LoongPayment'
    });

  
  
    onUnmounted(() =>{
    });
  
  </script>
  
  <style scoped lang="less">
        .errorPage{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .block{
                text-align: center;
                img{
                    width: 192px;
                    height: 128px;
                }
                .tip{
                    color:gray;
                    font-size: 14px;
                    margin-top: 15px;
                    font-weight: 600;
                }
            }
            .bottom{
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                text-align: center;
                background: #ffffff;

                img{
                    width: 100%;
                }
                .tips{
                    color: #777777;
                    padding: 10px 0;
                    font-size: 12px;
                }
            }
        }
  </style>
  
<template>
    <div class="waitPage">
        <div class="nav">{{ state.orderInfo.paymentName || 'Payment'}}</div>
        <div class="nav fixed">{{ state.orderInfo.paymentName || 'Payment'}}</div>
        <div v-if="state.type === 'loading'">
          <div class="block">
            <img src="@/assets/img/pay/0.gif" alt="">
             <div class="tip">
               Kindly , Wait For The Payment Result...
             </div>
             
         </div>
         <div class="block">
            <van-steps direction="vertical" :active="1" active-color="#0c77f9">
              <van-step>
                <div class="items">Submit UTR </div>
                <div class="items two">{{ state.utrData.subTime ? Util.filterTimes(new Date(state.utrData.subTime).getTime(),'america'):'-' }}</div>
                <div class="items two">{{ state.utrData.utr }}</div>
              </van-step>
              <van-step>
                <div class="items">Expected Completion</div>
                <div class="items two">{{ state.utrData.subTime ? Util.filterTimes(new Date(state.utrData.subTime).getTime() + 60000,'america'):'-' }}</div>
              </van-step>
            </van-steps>
          </div>
         <div class="block">
            <div class="btns">
                <div class="btn slt" @click="returnHandle">
                  <span>Repay</span>
                  <div class="small">I Have Not Pay</div>
                </div>
                <div class="btn" @click="goApp">
                  <span>Return App</span>
                  <div class="small">I Have Paid Succeed</div>
                </div>
            </div>
         </div>
        </div>
        <div v-if="state.type === 'success'">
          <div class="block">
            <img src="@/assets/img/pay/success.png" alt="" class="img">
             <div class="tip">
               Payment Success
             </div>
         </div>
         <div class="block">
            <van-steps direction="vertical" :active="1" active-color="#0c77f9">
              <van-step>
                <div class="items">Kindly , Your Deposit Has Arrived</div>
              </van-step>
              <van-step>
                <div class="items">Arrival Time</div>
                <div class="items two">{{ state.orderInfo.successTime ? Util.filterTimes(state.orderInfo.successTime,'america'):'-' }}</div>
              </van-step>
            </van-steps>
          </div>
         <div class="block">
            <div class="btns only">
                <div class="btn" @click="goApp">
                  <span>Return App</span>
                  <div class="small">Return And Check</div>
                </div>
            </div>
         </div>
        </div>
        <div v-if="state.type === 'timeout'">
          <div class="block">
            <img src="@/assets/img/pay/timeout.png" alt="" class="img">
            <div class="tip">
              The order does not exist or has expired,<br/> place a new order please
            </div>
         </div>
         <div class="block">
            <van-steps direction="vertical" :active="1" active-color="#0c77f9">
              <van-step>
                <div class="items">Wait For Payment </div>
                <div class="items two"> {{ state.orderInfo.createTime ? Util.filterTimes(state.orderInfo.createTime,'america'):'-'}}</div>
              </van-step>
              <van-step>
                <div class="items">Order expire </div>
                <div class="items two">{{ state.orderInfo.expireTime ? Util.filterTimes(state.orderInfo.expireTime,'america'):'-' }}</div>
              </van-step>
            </van-steps>
          </div>
         <div class="block">
            <div class="btns only">
                <div class="btn" @click="goApp">
                  <span>Return App</span>
                </div>
            </div>
         </div>
        </div>
        <div class="bomTips">
            <img src="@/assets/img/pay/bottom.jpg" alt="" class="visa">
            <div class="tips">
              <img src="@/assets/img/pay/dun.png" alt="">
              <span>Dear Member , 100% Secure Payment Powered</span>
            </div>
        </div>
       
    </div>
   </template>
   
   <script setup>
   import { ref, reactive, onMounted, toRefs,onUnmounted } from "vue";
   import { useRoute, useRouter } from "vue-router";
   import { Toast } from 'vant'
   import { Util } from '@/common/js/utils.js' 
    import Axios from '@/axios';
   let interVal;
     const route = useRoute();
     const router = useRouter()
     let state = reactive({
       type: route.query.type, // success | loading | timeout
       utrData: window.globalUtrData || {},
       id: route.query.id ,//|| 'DS230509215857C019',
       orderInfo:{}
     });
     
     onMounted(async () =>{
        document.title = 'LoongPayment'
        //Start polling the interface after 3 seconds
        if(state.type === 'loading'){
          if(window.globalData !== 'once'){
            window.location.href = `${window.location.origin}/nativepay?id=${state.id}`
            return
          }
          queryOrder()
        }else{
          const cacheData = sessionStorage.getItem('checkOutData');
          if(cacheData && cacheData != 'undefined'){
             state.orderInfo = JSON.parse(cacheData);
          }
        }
     });
     
     //quert api
     const queryOrder = async()=>{
        try {
        let res = await Axios('post','/safe/query',{
          id: state.id,
        });
          // console.log("res==",res)
        if(res.code === 1){
          res.data = JSON.parse(decrypt(res.data,state.id));
          state.orderInfo = res.data;
          if([1,2].includes(res.data.status)){
            state.type = res.data.status === 1 ? 'success' : 'timeout'
          }else{
            if(['loading'].includes(state.type)){
              interVal = setTimeout(()=>{
                queryOrder()
              },3000)
            }
           
          }
        }else if(res.code === 0){
          router.push(`/expired`)
        }
      } catch (error) {
        //
      }
     }

     // return App
     const returnHandle = ()=>{
        window.location.href = `${window.origin}/nativepay?id=${state.id}`
     }

     const goApp = ()=>{
        window.location.href = state.orderInfo.skipUrl
     }
   
     onUnmounted(() =>{
        if(interVal){
            // clearInterval(interVal)
            clearTimeout(interVal)
        }
     });
   
   </script>
   
   <style scoped lang="less">
         .waitPage{
             display: flex;
             flex-direction: column;
             background: #fafafa;
             .nav{
                font-size: 16px;
                font-weight: bold;
                color: #fef9ff;
                background: #fef9ff;
                
                border-radius: 0 0 10px 10px;
                padding: 10px 0;
                text-align: center;
                &.fixed{
                  position: fixed;
                  color: #ffffff;
                  top: 0;
                  left: 0;
                  right: 0;
                  background: #0c77f9;
                  background: linear-gradient(to right, rgb(74,157,246), #0c77f9);
                }
              }
             .block{
                 text-align: center;
                 padding: 45px 20px;
                 background: #ffffff;
                 border-bottom: 20px solid #fafafa;
                 img{
                     width: 130px;
                     margin: 0 auto;
                 }
                 .img{
                  width: 64px;
                  height: auto;
                 }
                 .tip{
                     color:gray;
                     font-size: 14px;
                     margin-top: 15px;
                     font-weight: 600;
                     text-align: center;
                 }
                 .items{
                    color: #333333;
                    font-size: 15px;
                    line-height: 30px;
                    font-weight: bold;
                    text-align: left;
                    &.two{
                      color: #888888;
                      font-weight: normal;
                    }
                 }
                 .btns{
                  display: flex;
                  justify-content: space-between;
                  &.only{
                    justify-content: center;
                  }
                  .btn{
                    padding: 8px 0;
                      font-size: 14px;
                      background: #eeeeee;
                      color:#888888;
                      font-weight: 600;
                      
                      text-align: center;
                      border-radius: 10px;
                      width: 48%;
                      .small{
                        font-size: 10px;
                      }
                      &.slt{
                        background: #0c77f9;
                        color: #ffffff;
                      }
                  }
                 }
                 
             }
             .bomTips{
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                text-align: center;
                background: #ffffff;

                .visa{
                    width: 100%;
                }
                .tips{
                    color: #777777;
                    padding: 10px 0;
                    font-size: 14px;
                     img{
                        width: 24px;
                        height: 24px;
                      }
                }
               
            }
         }
   </style>
   
<template>
  <div class="payPage">
    <van-skeleton title :row="25" :loading="loading">
      <div class="nav">{{ state.orderInfo.paymentName || 'Payment' }}</div>
      <div class="nav fixed">{{ state.orderInfo.paymentName || 'Payment' }}</div>
      <div class="wrap">
        <div class="card">
          <div class="amt">
            Amount Payable <span class="n"></span><span class="s">₹{{ state.orderInfo.preferentialAmount ?
              (state.orderInfo.amount * 100 - state.orderInfo.preferentialAmount * 100) / 100 : state.orderInfo.amount
            }}</span>
          </div>
          <div class="yh" v-if="state.orderInfo.preferentialAmount && state.orderInfo.preferentialAmount * 1 > 0">
            <div class="items"><span>Order Amount </span><span class="yj">₹{{ state.orderInfo.amount }}</span></div>
            <div class="items"><span style="color: red;">Discount </span><span class="xyh">- ₹{{
              state.orderInfo.preferentialAmount }}</span></div>
          </div>
        </div>
      </div>
      <div class="bottomInfo">
        <div class="payList">
          <template   v-for="(im, index) in state.payList"
            :key="im.name">
          <div v-if="im.isShow" class="im" :class="{ slt: state.selectName === im.name }" @click="selectPay(index)">
            <div class="lt">
              <img :src="im.icon" alt="">
              <!-- <span>{{ im.name }}</span> -->
            </div>
            <div style="font-weight: 700;" class="rt">
              ❯
            </div>
            <!-- 
            <div class="rt">
              <van-radio-group v-model="state.selectName" icon-size="17">
                <van-radio :name="im.name" checked-color="blue"></van-radio>
              </van-radio-group>
            </div> -->
          </div>

          </template>

        </div>
      </div>

      <div style="margin: 6px 0px; text-align: center;" class="divider"><span style="color:#1989fa;font-weight: bold;">OR</span></div>

      <div style="margin: 12px 15px; padding: 5px 20px; background: #FEFFFA; border-radius: 16px;">
                <div class="ewmImg">
            <img :src="state.QRImgUrl" />
            <!-- <div class="tips">
              If Use PhonePe Scan QR code to Pay
             ,
               You must copy the 12-digit UTR ,
               Then Paste and  Submit
              </div> -->
          </div>
      </div>

      <div class="bomTips fixed">
        <van-notice-bar :scrollable="false" :left-icon="dun" color="#ffffff" background="rgb(76, 147, 220)">
          <van-swipe vertical class="notice-swipe" :autoplay="3000" :touchable="false" :show-indicators="false">
            <van-swipe-item v-for="item in state.scrollList" :key="item">{{ item }}</van-swipe-item>
          </van-swipe>
        </van-notice-bar>
      </div>
    </van-skeleton>

    <!-- <van-dialog v-model:show="state.showPopup" width="280px" title="Notice" 
     @confirm="successConnfirm"
    show-cancel-button :showCancelButton="false" confirmButtonText='Confirm' :close-on-click-overlay="true">
     <span style="padding:10px ; width: 240px; display:block; margin: auto;line-height:25px; fontWeight:700">
      If Use PhonePe to Pay
      <br>
      You must copy 12-digit UTR
      <br>
      Then Paste and Submit
      <br>
     </span>

     <img style="width:100%; display:bl0ck; margin:auto;" src="@/assets/img/pay/tips12.jpg" alt="">
</van-dialog> -->
      

    <!-- <Utypayment /> -->

    <div class="bomTips"></div>

    <div class="overlay" v-if="apiLoading">
      <van-loading size="28px" vertical type="spinner" color="#1989fa">Loading...</van-loading>
    </div>
  </div>
</template>
  
<script setup>
import { ref, reactive, onMounted, toRefs, onUnmounted, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import QRCode from 'qrcode'
import { Toast } from 'vant'
import Axios from '@/axios';
import { appUrl } from '@/common/js/utils.js'
import dun from '@/assets/img/pay/dun.png'
import { decrypt } from '@/common/js/utils.js'
import Utypayment from './utrpayment.vue'

// import { Tab, Tabs } from "vant";
let interVal;
const route = useRoute();
const router = useRouter();
const loading = ref(false)
const apiLoading = ref(false)
const isShake = ref(false)
let state = reactive({
  QRImgUrl: '',
  showPopup :false,
  orderInfo: {},
  awakenInfo: {},
  id: route.query.id,// || 'DS230509215857C019',
  scrollList: ['Dear Member , 100% Secure Payment Powered'],
  selectName: '',
  activeNames: [],
  payList: [{
    isSelect: true,
    name: "paytm",
    icon: require('@/assets/img/pay/s1.png'),
    isShow:true

  },  {
    isSelect: true,
    name: "Gpay",
    icon: require('@/assets/img/pay/s3.png'),
    isShow:true
  }, {
    isSelect: true,
    name: "upi",
    icon: require('@/assets/img/pay/s4.png'),
    isShow:true
  },
  {
    isSelect: true,
    name: "PhonePe",
    icon: require('@/assets/img/pay/s2.png'),
    isShow:true
  }
  ]
});

onMounted(async () => {
  document.title = 'LoongPayment'
  window.globalData = 'once'
  if (route.query.token) {
    localStorage.setItem('dragonsign', route.query.token)
    window.location.href = `${window.location.origin}/nativepay?id=${state.id}`
  }
  //clear cache
  sessionStorage.setItem('checkOutData', '')
  loading.value = true;
  setTimeout(() => {
    loading.value = false;
    initOrder()
  }, 500)
  getQRcode()
});



const selectPay = (index) => {
  state.payList.forEach(e => {
    e.isSelect = false
  })

  getQRcode()
  state.payList[index].isSelect = true;
  state.selectName = state.payList[index].name;
  // if(state.selectName == 'PhonePe'){  // 当等于phone的时候先打开弹窗 ，点击确定才执行去phonepe
  //   state.showPopup = true 
  // }else{
  //   openApp(state.selectName)
  // }
  
 openApp(state.selectName)

}

//openApp
const openApp = (typeT) => {
  const type = typeT.toLowerCase();
  if (type === 'paytm') {
    window.location.href = state.awakenInfo?.paytm
  } else if (type == 'phonepe') {
    window.location.href = state.awakenInfo?.phonepe
  } else if (type == 'gpay') {
    window.location.href = state.awakenInfo?.gpay
  } else if (type == 'upi') {
    window.location.href = state.awakenInfo?.upi
  }
  // if (type === 'paytm') {
  //   if (state.orderInfo.ptmupiInfo == null || state.orderInfo.ptmupiInfo == undefined) {
  //     window.location.href = appUrl[type] + '?' + state.orderInfo.upiInfo
  //   } else {
  //     window.location.href = appUrl['paytmw'] + '?' + state.orderInfo.ptmupiInfo
  //   }
  // } else {
  //   window.location.href = appUrl[type] + '?' + state.orderInfo.upiInfo
  // }
}

// 弹窗点击确定的时候触发的方法
// const successConnfirm = () =>{
//   window.location.href = state.awakenInfo?.phonepe
// }


const initOrder = async function (type) {
  try {
    if (type !== 'loop') {
      apiLoading.value = true
    }
    let res = await Axios('post', '/safe/pay', {
      id: state.id,
    });
    if (res.code === 1) {
      res.data = JSON.parse(decrypt(res.data, state.id));
      state.orderInfo = res.data;
      state.awakenInfo = res.data.cashier
      console.log(state.awakenInfo,'state.awakenInfo')
      
      const phonePe = state.payList.find(item => item.name == "PhonePe");
      phonePe.isShow = state.awakenInfo?.phonepe == 'null' ? false : true;

      const paytm = state.payList.find(item => item.name == "paytm");
      paytm.isShow = state.awakenInfo?.paytm == 'null' ? false : true

      const gpay = state.payList.find(item => item.name == "Gpay");
      gpay.isShow = state.awakenInfo?.gpay == 'null' ? false : true

      const upi = state.payList.find(item => item.name == "upi");
      upi.isShow = state.awakenInfo?.upi == 'null' ? false : true

      // state.showPopup = true

      if (res.data.showText) {
        state.scrollList = [...state.scrollList, res.data.showText]
      }
      apiLoading.value = false
      
      //judge by status 0:未支付,1:支付成功,2:已超时
      if ([1, 2].includes(res.data.status)) {
        sessionStorage.setItem('checkOutData', JSON.stringify(res.data))
        router.push(`/checkout?id=${state.id}&type=${res.data.status === 1 ? 'success' : 'timeout'}`)
      } else {
        setTimeout(() => {
          queryOrder()
        }, 6000)
        getQRcode()
      }
    } else {
      apiLoading.value = false
      router.push('/expired')
    }
  } catch (error) {
    apiLoading.value = false
  }
}

//quert api
const queryOrder = async () => {
  try {
    let res = await Axios('post', '/safe/query', {
      id: state.id,
    });
    if (res.code === 1) {
      res.data = JSON.parse(decrypt(res.data, state.id));
      if ([0].includes(res.data.status)) {
        if (res.data.upi === state.orderInfo.upi) {
          setTimeout(() => {
            queryOrder()
          }, 6000)
        } else {
          initOrder('loop')
        }
      }
    }
  } catch (error) {
    //
  }
}

const getQRcode = () => {
  QRCode.toDataURL(state.awakenInfo?.qrcode, { errorCorrectionLevel: 'L', margin: 2, width: 180 }, (err, url) => {
    if (err) throw err
    state.QRImgUrl = url
  })
}

onUnmounted(() => {
  if (interVal) {
    clearInterval(interVal)
  }
});

</script>
  
<style scoped lang="less">
:deep(.van-cell--clickable) {
  background: #fafafa;
  text-align: left;
  font-weight: 600;
  border: 1px solid #eeeeee;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(+2px, 0, 0);
  }

  30%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(+4px, 0, 0);
  }

  50% {
    transform: translate3d(-4px, 0, 0);
  }
}

.payPage {
  font-size: 14px;
  width: auto;
  background: #fef9ff;
  height: 100%;

  .nav {
    font-size: 16px;
    font-weight: bold;
    color: #fef9ff;
    background: #fef9ff;

    // border-radius: 0 0 10px 10px;
    padding: 10px 0;
    text-align: center;

    &.fixed {
      position: fixed;
      color: #ffffff;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      background: #566aff;
      // background: linear-gradient(to right, rgb(74,157,246), #0c77f9);
    }
  }

  .wrap {
    padding: 20px 15px 5px;
    background: #ffffff;
    background-image: linear-gradient(to bottom right, #566aff, #a7cbf3);
    border-radius: 0 0 40% 40%;
    padding-bottom: 0px;

    .card {
      padding: 16px 0;
      border-radius: 10px;
      background-color: #fff;
    }
  }

  .amt {
    font-size: 15px;
    font-weight: bold;
    padding: 0 30px;

    span.n {
      font-size: 28px;
    }

    span.s {
      font-size: 28px;
      margin-left: 5px;
      color: rgb(12, 119, 249);
    }
  }

  .yh {
    font-size: 15px;
    padding: 0 30px;
    font-weight: 600;
    margin-top: 10px;

    .items {
      line-height: 28px;
      display: flex;
      justify-content: space-between;
      color: gray;

      .yj {
        text-decoration: line-through;
      }

      .xyh {
        color: red;
      }
    }
  }

  .bottomInfo {
    text-align: center;
    // background: #ffffff;
    // margin: 12px 15px;
    // border-radius: 15px;
    // box-shadow: 1px 1px 10px rgb(216, 230, 248);
    color: #000000;
    position: relative;
    

    .payList {
      // background: #FEFFFA;
      padding: 10px;
      border-radius: 16px;

      .im {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 10px;
        border-radius: 5px;
        border-bottom: 1px solid #fafafa;
        box-shadow: 0px 4px 6px #cccccc;
        // margin: 12px 0;
        margin-bottom: 16px;
        // border: 1px solid #ccc;
        background: #fff;

        &:last-child {
          border-bottom: none;
          margin-bottom: 0px;
        }

        .lt {
          display: flex;
          align-items: center;

          img {
            // border-radius: 50%;
            width: 50%;
            // height: 37px;
          }

          span {
            color: #000000;
            margin-left: 5px;
          }
        }

        .rt {}
      }

      .ewm {
        margin-top: 20px;
      }
    }

  }
      
          .ewmImg {
          text-align: center;

          img {
            width: 180px;
            height: 180px;
          }

          .tips {
            font-size: 13px;
            font-weight: 700;
            color: #333333;

          }
        }

  .payBtn {
    margin: 30px 20px 30px;

    :deep(.van-button) {
      width: 100%;
      font-weight: 600;
      font-size: 15px;

      &.gray {
        background: #cccccc;
        border: 1px solid #cccccc;
      }
    }
  }

  .bomTips {
    color: #ffffff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 10px 10px 0 0;
    font-size: 13px;
    height: 36px;
    background: none;

    :deep(.notice-swipe) {
      height: 34px;
      line-height: 34px;
    }

    :deep(.van-notice-bar) {
      border-radius: 10px 10px 0 0;
      width: 100%;
      text-align: left;
    }

    &.fixed {
      bottom: 0;
      left: 0;
      right: 0;
      position: fixed;
      z-index: 10;
      background: rgb(76, 147, 220);
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

}

.utrInfo {
  .tl {
    background: #fef9ff;
    text-align: center;
    padding: 10px 0;
    font-weight: 600;
    color: #045a8a
  }

  .content {
    padding: 20px 0;

    .bt {
      font-weight: 600;
      text-align: center;
      color: #666666;
    }

    .time {
      width: 100px;
      margin: 20px auto;
      text-align: center;
      display: flex;
      background: #fef9ff;
      border-radius: 20px;
      padding: 10px 10px 5px;
      align-items: center;
      justify-content: center;

      &.shake {
        animation: shake 800ms ease-in-out infinite;
      }

      :deep(.van-count-down) {
        display: flex;

        .block {
          color: #045a8a;
          font-weight: 600;
          font-size: 18px;
          text-align: center;

          .mc {
            font-weight: normal;
            font-size: 12px;
            color: gray;
          }
        }

        .colon {
          font-weight: 600;
          padding: 0 5px;
        }
      }
    }

    .btn {
      background: #0c77f9;
      font-weight: 600;
      color: #ffffff;
      width: 100px;
      height: 38px;
      line-height: 38px;
      border-radius: 8px;
      text-align: center;
      margin: 0 auto 20px;
    }
  }

  .bom {
    background: #fff5d5;
    color: #bc6a06;
    font-size: 13px;
    padding: 5px 15px;
    text-align: center;
  }
}

.overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #0000001f;
  display: flex;
  justify-content: center;
  align-items: center;
}


.goQR {
  width: 100%;
  height: 80px;
  position: relative;

  .breathing-circle {
    width: 20px;
    height: 80px;
    background-color: #007bff;
    // border-radius: 20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    left: 0px;
    animation: breathe 2s infinite alternate;
    font-size: 12px;
    font-weight: 600;
    writing-mode: vertical-rl;
    /* 文本竖直排列 */
    text-align: center;
    /* 文本居中 */
    line-height: 22px;
    color: #fff;
  }

  .btnStyles {
    width: 10 0%;
    height: 80px;
    // background: red;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    // padding-left: 30px;
  }

  @keyframes breathe {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(1.2);
    }
  }
}


::v-deep .van-dialog__header {
  color: red !important;
}

::v-deep .van-dialog__confirm{
  font-size: 20px;
}
</style>
  
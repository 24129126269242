
import CryptoJS from "crypto-js";

export const decrypt = (word, keyStr) => {
    try {
        keyStr = keyStr ? keyStr.substring(0, 16) : '';
        const key = CryptoJS.enc.Utf8.parse(keyStr);
        // const base64 = CryptoJS.enc.Base64.parse(word);
        // const srcs = CryptoJS.enc.Base64.stringify(base64);
        const decrypt = CryptoJS.AES.decrypt(word, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.ZeroPadding,
        });
        return CryptoJS.enc.Utf8.stringify(decrypt).toString().replace(/[^\x20-\x7E]/g, '');
    } catch (error) {
        return JSON.stringify({})
    }
}
export const browserType = () => {
    let ua = navigator.userAgent.toLowerCase();
    let type = 'browser';
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        type = 'wx';
    } else if (ua.match(/AlipayClient/i) == "alipayclient") {
        type = 'alipay';
    }
    return type
}
export const systemJudge = () => {
    var systemFlag = "";
    var u = navigator.userAgent;
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
        systemFlag = 'Android';
    } else if (isiOS) {
        systemFlag = 'IOS';
    }
    return systemFlag;
}

//根据URL获取文件格式
export const urlGetFileType = (url) => {
    return url.slice(url.lastIndexOf('.') + 1).toLowerCase()
}

export const appUrl = {
    phonepe: 'phonepe://pay',
    paytm: 'paytmmp://pay',
    paytmw: 'paytmmp://cash_wallet',
    paytm2: 'paytmmp://upi',
    gpay: 'gpay://upi/pay',
    upiqr: 'upi://pay',
    upi: 'upi://mandate',
}

export const Util = {
    filterTimes(value, flag) {
        if (value) {
            let _date = new Date(value);//如果date为13位不需要乘1000
            if (!_date.getFullYear()) {
                _date = new Date(value.substring(0, 19))
            }
            let Y = _date.getFullYear();
            let M = (_date.getMonth() + 1 < 10 ? '0' + (_date.getMonth() + 1) : _date.getMonth() + 1);
            let D = (_date.getDate() < 10 ? '0' + (_date.getDate()) : _date.getDate());
            let h = (_date.getHours() < 10 ? '0' + _date.getHours() : _date.getHours());
            let m = (_date.getMinutes() < 10 ? '0' + _date.getMinutes() : _date.getMinutes());
            let s = (_date.getSeconds() < 10 ? '0' + _date.getSeconds() : _date.getSeconds());
            if (flag == 'date') {
                return Y + '-' + M + '-' + D
            } else if (flag == 'time') {
                return h + ':' + m
            } else if (flag == 'minute') {
                return Y + '-' + M + '-' + D + ' ' + h + ':' + m;
            } else if (flag === 'america') {
                return D + '/' + M + ' ' + Y + ' ' + h + ':' + m + ':' + s;
            } else {
                return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s;
            }
        }
    },

}

